// This is basically the same as /routers/tenant.ts except that it uses /routes/lobby instead of /routes/tenant.
// We use different routers because some paths (like '/') are different depending on whether or not the request path
// contains a subdomain. Since the routes are in different files, we need different routers.

// TODO: could we import most of this from /routers/tenant.ts to reduce duplication here?

import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes/lobby'
import { useAuthStore } from '@/stores/auth.store'
import { rootUrl, subdomainUrl } from '@/utils/helpers/urlHelpers'
import { showSnackbar } from '@/utils/showSnackbar'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  if (!authStore.userRoles.length) {
    // If there are no userRoles, it could be because the page was refreshed and the store is empty. In that case,
    // we need to fetch the current user to re-populate the store. There may be a better way to do this instead of
    // making extra requests for the current user.
    
    
    // CJS login/logout test - this wasn't here before, but it didn't seem to help
    //authStore.fetchCurrentUser()
  }

    // Used for shared/lobby pages like /login, /signup, etc.
  if (to.meta.forceRedirect && location.host != rootUrl().replace(/\//g, '')) {
    location.href = rootUrl() + to.name
  }
})

// For every visited path, check if user's role allows access. If not, redirect to their dashboard page.
// We use beforeResolve here (instead of guards that trigger earlier) to allow all the async route components
// to complete so that the authStore is populated with a user and roles.
router.beforeResolve(async (to, from) => {
  console.log('lobby router')

  // The store needs to be created inside this block because pinia isn't installed until the router is installed: https://github.com/vuejs/pinia/discussions/971
  // const authStore = useAuthStore()
  // const userRoles = await authStore.userRoles
  // const userAccounts = authStore.userAccounts
  // const pendingTeamInvites = await authStore.currentUser?.pending_participations

  // // Ideally, this should be handled earlier (before the beforeResolve step) so that nothing renders before redirecting,
  // // but i couldn't get a beforeEnter route guard to work because the user & accounts may not be loaded (on page refresh).
  // if (to.name == 'lobby') {
  //   // If the user has a pending invite to join a team, redirect them to the /contact-info page.
  //   if (pendingTeamInvites && pendingTeamInvites.length) {
  //     return { name: 'contactInfo' }
  //   // If the user only belongs to one account, redirect them to that subdomain.
  //   } else if (userAccounts && userAccounts.length === 1) {
  //     console.log('redirect to subdomain')
  //     const subdomain = userAccounts[0].subdomain
  //     location.href = subdomainUrl(subdomain)
  //   }
  // }

//  if (to.meta.roles.includes('ALL')) {
//    console.log('public route:', to.name)
//    console.log("public path:", to.path)
//  // } else if (to.meta.roles.some((role) => userRoles.includes(role))) {
//  //   console.log('route matched', to.name)
//  } else {
//    console.log('invalid route', to.name)
//    // showSnackbar('You do not have access to that page', 'error')
//    return { name: 'lobby' }
//  }

  const authStore = useAuthStore()
  //const userRoles = await authStore.userRoles // CJS by moving this into the code it prevents race condition where await doesn't work in this context

  if (to.meta.roles.includes('ALL')) {
    console.log('public route:', to.name)
  //} else if (to.meta.roles.some((role) => userRoles.includes(role))) {
  // CJS } else if (to.meta.roles.some((role) => authStore.userRoles.includes(role))) {
//  } else if (false) {
//    // This functionality is also in currentUser.ts (needToAcceptTOU), but because pinia isn't installed until the router is installed, it is duplicated here
//    if (authStore.currentUser) {
//      if (to.meta.layout != 'DashboardLayout') {
//        authStore.setCurrentAccountIndex(null)
//        authStore.setCurrentRole('')
//        authStore.setCurrentParticipant(null)
//      }
//      else {  // If set to go to a Dashboard & authStore.currentRole == '', return to profile - this happens on a browser referesh of any Dashboard
//              // This may be undesirable - may want to save more info (like entire authStore state) in session storage (search for sessionstorage)
//        if (authStore.currentRole == '') {
//          return { name: 'profile' }
//        }
//      }
//
//      if (authStore.currentUser.TOU) {
//        const TOUdate = new Date(authStore.currentUser.TOU)
//        const minTOUDate = new Date('2024-10-02')
//        if (TOUdate < minTOUDate) { return { name: 'terms-of-use'} }
//      }
//      else { return { name: 'terms-of-use'} }
//    }
//    console.log('route matched', to.name)
  } else if (authStore.isAuthenticated) {
    if (to.meta.layout != 'DashboardLayout') {
      authStore.setCurrentAccountIndex(null)
      authStore.setCurrentRole('')
      authStore.setCurrentParticipant(null)
    }
    else {  // If set to go to a Dashboard & authStore.currentRole == '', return to profile - this happens on a browser referesh of any Dashboard
            // This may be undesirable - may want to save more info (like entire authStore state) in session storage (search for sessionstorage)
      if (authStore.currentRole == '') {
        return { name: 'profile' }
      }

      if (!(to.meta.roles.some((role) => authStore.userRoles.includes(role)))) {
        return { name: 'profile' }
      }
    }

    //console.log('authStore.currentUser:', authStore.currentUser)
    //if (authStore.currentUser) {
    // Created and using authStore.isTOU because this appears to force TOU to be populated
    // without using this or using authStore.currentUser, TOU isn't populated
// CJS - COMMMENTED OUT ON 2/6/2025 - This should be handled when logging in
// CJS - unCOMMMENTED OUT ON 2/7/2025 - while this is hanlded when logging in, it is not handled when directly typeing in theleeg.com/profile after logging in
    if (authStore.isTOU) {
      if (authStore.currentUser.TOU) {
        const TOUdate = new Date(authStore.currentUser.TOU)
        const minTOUDate = new Date('2024-10-02')
        if (TOUdate < minTOUDate) { return { name: 'terms-of-use'} }
      }
      else { return { name: 'terms-of-use'} }
    }
    else {
      // CJS - This definitely works - console message was shown
      // console.log('!isTOU:',authStore.currentUser)
      return { name: 'terms-of-use'}
    }

    console.log('route matched', to.name)
  } else {
    console.log('invalid route', to.name)
    // showSnackbar('You do not have access to that page', 'error')
    return { name: 'lobby' }
  }
})

export default router
